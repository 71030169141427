<template>
  <PageSection
    class="screen consulting-section"
    :bg="bg"
    :theme="true"
  >
    <template #default>
      <Breadcrumbs />

      <div class="container">
        <div class="header">
          <h3
            class="title"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: 300}"
          >{{content?.title_consulting}}</h3>
        </div>

        <div class="features">
          <Feature
            v-for="(stage, stageId) in content?.steps_consulting"
            :key="`featue-${stage?.text}`"
            class="feature-consult"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: (200 + 100 * stageId)}"
          >
            <template #symbol>
              <Icon :name="getIcon(stage?.icon)?.name" />
            </template>
            <template #default>
              <div class="text-small">{{stage?.text}}</div>
            </template>
          </Feature>
        </div>

        <VButton
          :tag="'button'"
          type="button"
          @click="openPopup(bg)"
          v-inview-animate="{name: 'fade-down', duration: 500, delay: 700}"
        >{{content?.button_consulting}}</VButton>
      </div>
    </template>
  </PageSection>
</template>

<script>
import Breadcrumbs from '../../../components/Breadcrumbs.vue';
import PageSection from '../../../components/PageSection.vue';
import VButton from '../../../components/VButton.vue';
import Icon from '../../../components/Icon.vue';
import Feature from '../../../components/Feature.vue';

export default {
  components: {
    PageSection,
    VButton,
    Icon,
    Feature,
    Breadcrumbs,
  },
  name: 'ConsultingSection',
  props: {
    content: {
      type: Object,
    },
  },
  emits: ['openPopup'],
  computed: {
    bg() {
      return {
        background: this.content?.background_consulting,
      };
    },
  },
  methods: {
    openPopup(bg) {
      this.$emit('open-popup', bg);
    },
  },
};
</script>

<style lang="scss" scoped>
.consulting-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include screen("sm", max) {
    justify-content: flex-end;
  }

  @include wRule(
    padding,
    (
      xl: 0 0 120,
      lg: 0 0 70,
      sm: 150 0 120,
    )
  );

  @media screen and (min-width: 1940px) {
    padding-bottom: 230px;
  }

  ::v-deep(.breadcrumbs) {
    @include wRule(
      margin-bottom,
      (
        xl: 70,
        lg: 44,
        sm: 0,
      )
    );

    @include wRule(
      padding-top,
      (
        xl: 142,
        lg: 118,
        sm: 0,
      )
    );
  }

  ::v-deep(.container) {
    @media screen and (min-width: 1940px) {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  ::v-deep(.bg-media) {
    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(26, 42, 68, 0.136) 0%,
        rgba(19, 29, 45, 0.76) 48.41%
      );
      z-index: 2;

      @include rule(
        opacity,
        (
          xl: 0.9,
          sm: 1,
        )
      );
    }
  }

  .features {
    display: flex;
    justify-content: flex-start;

    @include screen("sm", max) {
      flex-direction: column;
    }

    @include wRule(
      margin-top,
      (
        xl: 40,
        lg: 55,
        sm: 66,
      )
    );

    @media screen and (min-width: 1940px) {
      justify-content: space-between;
      width: 100%;
    }
  }

  ::v-deep(.feature) {
    @include wRule(
      width,
      (
        xl: 360,
        lg: 276,
        sm: 100%,
      )
    );

    @media screen and (min-width: 1940px) {
      flex: 1;
    }

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 90,
          lg: 32,
          sm: 0,
        )
      );

      @include wRule(
        margin-top,
        (
          sm: 65,
        )
      );
    }
  }

  ::v-deep(.v-button) {
    @include wRule(
      min-width,
      (
        xl: 257,
        lg: 202,
        sm: 100%,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 60,
          lg: 25,
          sm: 105,
        )
      );
    }
  }
}
</style>
