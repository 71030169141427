<template>
  <div class="page-container">
    <ConsultingSection
      :content="pageContent"
      @open-popup="openPopup"
      id="consulting"
    />

    <ManagementSection
      :content="pageContent"
      id="managment"
      @open-popup="openPopup"
    />

    <teleport to="#popups">
      <transition name="fade">
        <Popup
          v-if="popupActive"
          @modal-closed="popupActive = false"
          :isShown="popupActive"
          :bg="bg"
          :blur="true"
        >
          <template #default>
            <PopupRequestForm @modal-closed="closePopup" />
          </template>
        </Popup>
      </transition>
    </teleport>
  </div>
</template>

<script>
import api from '../../tools/api';
import Popup from '../../components/Popup.vue';
import PopupRequestForm from './components/PopupRequestForm.vue';
import ConsultingSection from './components/ConsultingSection.vue';
import ManagementSection from './components/ManagementSection.vue';

export default {
  name: 'Services',
  components: {
    Popup,
    PopupRequestForm,
    ConsultingSection,
    ManagementSection,
  },
  data() {
    return {
      pageContent: {},
      contentLoading: true,
      popupActive: false,
      bg: {},
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('consulting', true);
      this.contentLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
    openPopup(bg) {
      this.popupActive = true;
      this.bg = bg;
    },
    closePopup() {
      this.popupActive = false;
    },
  },
  watch: {
    lang() {
      this.getPageContent();
    },
  },
  created() {
    this.getPageContent();
  },
};
</script>

<style lang="scss" scoped>
</style>
