<template>
  <PageSection
    class="screen management-section"
    :bg="bg"
    :theme="true"
  >
    <template #default>
      <div class="container">
        <div class="header">
          <h3
            class="title"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: 300}"
          >{{content?.title_management}}</h3>
        </div>

        <ul class="features">
          <li
            class="feature"
            v-for="(stage, stageId) in content?.steps_management"
            :key="`featue-${stage?.text}`"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: (200 + 100 * stageId)}"
          >
            <div class="feature-label">{{stage?.label}}</div>
            <div class="text-small">{{stage?.text}}</div>
          </li>
        </ul>

        <VButton
          :tag="'button'"
          type="button"
          @click="openPopup(bg)"
          v-inview-animate="{name: 'fade-down', duration: 500, delay: 700}"
        >{{content?.button_management}}</VButton>
      </div>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../components/PageSection.vue';
import VButton from '../../../components/VButton.vue';

export default {
  name: 'ManagementSection',
  components: {
    PageSection,
    VButton,
  },
  props: {
    content: {
      type: Object,
    },
  },
  emits: ['openPopup'],
  computed: {
    bg() {
      return {
        background: this.content?.background_management,
      };
    },
  },
  methods: {
    openPopup(bg) {
      this.$emit('open-popup', bg);
    },
  },
};
</script>

<style lang="scss" scoped>
.management-section {
  display: flex;

  @include wRule(
    padding,
    (
      xl: 230 0 120,
      lg: 180 0 70,
      sm: 62 0 120,
    )
  );

  @media screen and (min-width: 1940px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 230px;
  }

  ::v-deep(.bg-media) {
    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(26, 42, 68, 0.136) 0%,
        rgba(19, 29, 45, 0.76) 48.41%
      );
      z-index: 2;

      @include rule(
        opacity,
        (
          xl: 0.9,
          sm: 1,
        )
      );
    }
  }

  .features {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @include screen("sm", max) {
      flex-direction: column;
    }

    @media screen and (min-width: 1940px) {
      justify-content: space-between;
      width: 100%;
    }

    @include wRule(
      margin-top,
      (
        xl: 118,
        lg: 88,
        sm: 46,
      )
    );
  }

  .feature {
    display: block;

    @include wRule(
      width,
      (
        xl: 243,
        lg: 174,
        sm: 100%,
      )
    );

    @media screen and (min-width: 1940px) {
      flex: 1;
    }

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 56,
          lg: 32,
          sm: 0,
        )
      );

      @media screen and (min-width: 1940px) {
        margin-left: 100px;
      }

      @include wRule(
        margin-top,
        (
          sm: 47,
        )
      );
    }
  }

  .feature-label {
    position: relative;
    display: block;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.6;

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 20,
        sm: 32,
      )
    );

    &::after {
      display: block;
      content: "";
      background: color(main);

      @include wRule(
        width,
        (
          xl: 62,
          lg: 52,
          sm: 78,
        )
      );

      @include wRule(
        height,
        (
          xl: 3,
          lg: 2,
          sm: 5,
        )
      );

      @include wRule(
        margin-top,
        (
          xl: 5,
          lg: 3,
          sm: 2,
        )
      );
    }

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          xl: 24,
          lg: 16,
          sm: 13,
        )
      );
    }
  }

  ::v-deep(.text-small) {
    line-height: 1.6;
  }

  ::v-deep(.v-button) {
    @include wRule(
      min-width,
      (
        xl: 257,
        lg: 202,
        sm: 100%,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 105,
          lg: 105,
          sm: 110,
        )
      );
    }
  }
}
</style>
