<template>
  <VeeForm
    method="post"
    :action="request?.FormUrl"
    :validation-schema="validationShema"
    class="popup-request-form"
    @submit="onSubmit"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <PopupForm v-if="formNotSend">
        <template #header>
          <h3
            class="title-small"
            v-inview-animate="{name: 'fade-down', duration: 300, delay: 300}"
          >{{request?.FormTitle}}</h3>
        </template>
        <template #content>
          <fieldset class="fieldset">
            <div
              class="subtitle"
              v-inview-animate="{name: 'fade-down', duration: 300, delay: 400}"
            >
              <Icon :name="request?.Groups?.Contacts?.Icon" />
              <span class="subtitle-text">{{request?.Groups?.Contacts?.Name}}</span>
            </div>
            <div class="fields grid-3">
              <div class="wrapper">
                <BaseInput
                  name="name"
                  type="text"
                  :label="request?.Groups?.Contacts?.Fields?.Name?.Label"
                  v-inview-animate="{name: 'input-transition', duration: 300, delay: 500}"
                />
                <ErrorMessage
                  name="name"
                  class="error"
                />
              </div>

              <div class="wrapper">
                <div class="phone">
                  <BaseSelect
                    :options="callingCodes"
                    :name="'callingCode'"
                    :initial-selected="userCallingCode"
                    :class="'calling-code'"
                    :mobile-title="request?.Groups?.Contacts?.Fields?.CallingCode?.MobileTitle"
                    :search-placeholder="request?.Groups?.Contacts?.Fields?.CallingCode?.SearchPlaceholder"
                    v-inview-animate="{name: 'input-transition', duration: 300, delay: 600}"
                    :uid="'countryName'"
                  />
                  <BaseInput
                    name="phone"
                    type="text"
                    inputmode="number"
                    :label="request?.Groups?.Contacts?.Fields?.Phone?.Label"
                    :class="'phone-number'"
                    v-inview-animate="{name: 'input-transition', duration: 300, delay: 600}"
                  />
                </div>
                <ErrorMessage
                  name="phone"
                  class="error"
                />
              </div>

              <div class="wrapper lg-100">
                <BaseInput
                  name="email"
                  type="email"
                  :label="request?.Groups?.Contacts?.Fields?.Email?.Label"
                  v-inview-animate="{name: 'input-transition', duration: 300, delay: 700}"
                />
                <ErrorMessage
                  name="email"
                  class="error"
                />
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <div
              class="subtitle"
              v-inview-animate="{name: 'fade-down', duration: 300, delay: 800}"
            >
              <Icon :name="request?.Groups?.Comment?.Icon" />
              <span class="subtitle-text">{{request?.Groups?.Comment?.Name}}</span>
            </div>
            <div class="fields">
              <div class="wrapper">
                <BaseTextarea
                  name="message"
                  :label="request?.Groups?.Comment?.Fields?.Message?.Label"
                  v-inview-animate="{name: 'input-transition', duration: 300, delay: 900}"
                />
                <ErrorMessage
                  name="message"
                  class="error"
                />
              </div>
            </div>
          </fieldset>
        </template>
        <template #bottom>
          <VButton
            :tag="'button'"
            type="submit"
            class="submit"
            v-inview-animate="{name: 'fade-down', duration: 300, delay: 1000}"
          >{{request?.FormSubmitName}}</VButton>
        </template>
      </PopupForm>
      <SuccessMessage
        :data="sendSuccess ? successMessage : errorMessage"
        v-else
        @modal-closed="closePopup"
      ></SuccessMessage>
    </transition>
  </VeeForm>
</template>

<script>
import * as yup from 'yup';
import { setLocale } from 'yup';
import { unfreeze } from '@/tools/blockScroll';
import { Form as VeeForm, ErrorMessage } from 'vee-validate';
import PopupForm from '../../../components/Forms/PopupForm.vue';
import Icon from '../../../components/Icon.vue';
import BaseInput from '../../../components/Forms/components/BaseInput.vue';
import BaseSelect from '../../../components/Forms/components/BaseSelect.vue';
import BaseTextarea from '../../../components/Forms/components/BaseTextarea.vue';
import VButton from '../../../components/VButton.vue';
import SuccessMessage from '../../../components/SuccessMessage.vue';

export default {
  components: {
    PopupForm,
    VeeForm,
    ErrorMessage,
    Icon,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    VButton,
    SuccessMessage,
  },
  name: 'PopupRequestForm',
  emits: ['modalClosed'],
  data() {
    return {
      formNotSend: true,
      sendSuccess: false,
      validationShema: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    getErrorsList() {
      const errorList = this.$store.getters.getErrorList;
      return errorList;
    },
    callingCodes() {
      return this.$store.getters.getCallingCodes;
    },
    userCallingCode() {
      return this.$store.getters.getUserCallingCode;
    },
    successMessage() {
      return {
        Icon: 'check',
        Title: this.getName('form_request_success_title')?.default,
        Text: this.getName('form_request_success_description')?.default,
        ButtonName: this.getName('close_button')?.default,
      };
    },
    errorMessage() {
      return {
        Icon: 'cross',
        Title: this.getName('form_request_error_title')?.default,
        Text: this.getName('form_request_error_description')?.default,
        ButtonName: this.getName('close_button')?.default,
      };
    },
    request() {
      return {
        FormTitle: this.getName('form_consulting_title')?.default,
        FormSubmitName: this.getName('FormSubmitButton')?.secondary,
        FormUrl: '/',
        Groups: {
          Contacts: {
            Icon: 'person',
            Name: this.getName('fieldset_contacts')?.default,
            Fields: {
              Name: {
                Label: this.getName('field_name')?.default,
              },
              CallingCode: {
                MobileTitle: this.getName('field_dial_code')?.default,
                SearchPlaceholder: '',
              },
              Phone: {
                Label: this.getName('field_phone')?.default,
              },
              Email: {
                Label: this.getName('field_email')?.default,
              },
            },
          },
          Comment: {
            Icon: 'comment',
            Name: this.getName('fieldset_comment')?.default,
            Fields: {
              Message: {
                Label: this.getName('field_comment')?.default,
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async onSubmit(data) {
      console.log(data);
      const response = await fetch(`${process.env.VUE_APP_FORM_HOST}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data?.email,
          phone: `${data?.callingCode}${data?.phone}`,
          name: data?.name,
          title: 'Заявка на дополнительные услуги с сайта SKYPRO AVIATION',
          message: data?.message,
        }),
      });

      if (response.ok) {
        this.formNotSend = false;
        this.sendSuccess = true;
      } else {
        this.formNotSend = false;
        this.sendSuccess = false;
        console.log('error');
      }
    },
    closePopup() {
      this.$emit('modal-closed');
      unfreeze();
    },
    setValidationShema(lang) {
      const errorList = this.getErrorsList[lang];
      setLocale(errorList);
      this.validationShema = yup.object({
        name: yup.string().required(),
        email: yup
          .string()
          .required()
          .email(),
        phone: yup.number().required(),
        message: yup.string().required(),
      });
    },
  },
  watch: {
    lang(value) {
      this.setValidationShema(value);
    },
  },
  created() {
    const lang = this.$store.getters.getLang;
    this.setValidationShema(lang);
  },
};
</script>

<style lang="scss" scoped>
.popup-request-form {
  display: block;
  box-sizing: border-box;
  width: 100%;

  @include wRule(
    max-width,
    (
      xl: 1440,
      lg: 994,
      sm: 100%,
    )
  );
}
</style>
